<template>
  <div class="notifyWizardCustom">
    <LoadingPlaceholder v-if="loading" />
    <template v-else-if="sendingWasSuccessful == false">
      <template v-if="notificationMedia">
        <label>{{ 'Notify over' }}</label>
        <select
          v-model="selectedMedia"
          class="mb-4 form-control col-2"
        >
          <option
            v-for="(media, i) in notificationMedia"
            :key="`mediaType${i}`"
            :value="media"
          >
            {{ media }}
          </option>
        </select>
      </template>

      <big class="d-inline-block mb-2">
        <font-awesome-icon
          class="mr-2"
          icon="user"
        />
        <b>Recipient:</b> {{ recipient }} ({{ country_countryToObject(language).language }})
      </big>
      <textarea
        ref="messageArea"
        v-model="message"
        :class="['form-control', { 'is-invalid': this.$validator.errors.has(`Message`) }]"
        placeholder="Please enter your custom message here."
        rows="5"
      />
      <span
        v-show="this.$validator.errors.has(`Message`)"
        class="badge badge-danger"
      >{{ this.$validator.errors.first(`Message`) }}</span>
      <div
        class="row mt-3"
      >
        <div class="col-12">
          <div class="cell">
            <h5>{{ 'Placeholders' }}</h5>
            <div class="row">
              <button
                class="btn btn-secondary"
                @click="addPlaceholder('{installation_name}')"
              >
                {{ $t('installationName') }}
              </button>
              <button
                class="btn btn-secondary ml-3"
                @click="addPlaceholder('{installation_url}')"
              >
                {{ 'Installation URL' }}
              </button>
              <button
                class="btn btn-secondary ml-3"
                @click="addPlaceholder('{current_date_time}')"
              >
                {{ $t('date') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        v-if="message.length > 0"
        class="btn btn-primary float-right mt-3"
        @click="sendCustomMessage(message)"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>
          {{ $t('send') }}
        </span>
      </button>
      <button
        v-else
        class="btn btn-primary float-right mt-3 cursor-not-allowed"
        disabled
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>
          {{ $t('send') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <p
      v-else-if="sendingWasSuccessful == true"
      class="green text-center"
    >
      <font-awesome-icon
        class="mr-1"
        icon="check"
      />
      <span>
        Message was successfully send.
      </span>
    </p>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import { countryMixin } from '@/mixins/countryMixin.js';
import historyAdapter from '@/mixins/historyAdapter.js';
import jiraAdapter from '@/mixins/jiraAdapter.js';

export default {
  name: 'NotifyWizardCustom',
  mixins: [
    jiraAdapter,
    historyAdapter,
    errorMixin,
    countryMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    recipientId: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      message: "",
      number: null,
      email: null,
      recipient: null,
      language: null,
      loading: true,
      sendingWasSuccessful: false,
      notificationMedia: null,
      selectedMedia: null
    }
  },
  created () {
    this.getContact();
  },
  methods: {
     addPlaceholder (val) {
      if (this.message == null) {
          this.message = "";
        }
        var messageArea = this.$refs.messageArea;
        var messagePosition = messageArea.selectionStart
        var msg = this.message;
        this.message = msg.substring(0, messagePosition) + val + msg.substring(messagePosition);
        messageArea.focus();
    },
    getContact () {
      this.axios.get('/Contact/GetContact', { params: { 'contactId': this.recipientId }})
        .then((response) => {
          if (response && response.data && response.data.phone) {
            this.number = response.data.phone;
          }
          if (response && response.data && response.data.email) {
            this.email = response.data.email;
          }
          if ((response && response.data && response.data.firstname) && (response && response.data && response.data.surname)) {
            this.recipient = response.data.firstname + ' ' + response.data.surname;
          }
          if (response && response.data && response.data.language) {
            this.language = response.data.languageCode[0];
          }
          
          this.notificationMedia = [];
          if(this.number) {
            this.notificationMedia.push('SMS');
            this.selectedMedia = this.notificationMedia[0];
          }
          if(this.email) {
            this.notificationMedia.push('Email');
            this.selectedMedia = this.notificationMedia[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendCustomMessage (message) {
      this.loading = true;

      var jiraMessage = "";
      var historyMessage = "";
      var notificationRecipient = null;
      if(this.selectedMedia == 'SMS') {
        jiraMessage = "\\\\ Recipient: " + this.recipient + "; Number: " + this.number + " \\\\ Message: " + this.message;
        historyMessage = "Recipient: " + this.recipient + "; Number: " + this.number + "; Message: " + this.message, this.selectedMedia;
        notificationRecipient = this.number;
      }
      else if(this.selectedMedia == 'Email') {
        jiraMessage = "\\\\ Recipient: " + this.recipient + "; Email: " + this.email + " \\\\ Message: " + this.message;
        historyMessage = "Recipient: " + this.recipient + "; Email: " + this.email + "; Message: " + this.message, this.selectedMedia;
        notificationRecipient = this.email;
      }
      
      let jiraRequest = this.sendJiraComment(this.installationId, jiraMessage);
      let historyRequest = this.sendHistoryComment(this.installationId, historyMessage, this.selectedMedia, notificationRecipient);

      let customMessage = {
        'installationId': this.installationId,
        'contactPersonId': this.recipientId,
        'message': message,
        'media': this.selectedMedia
      };

      this.axios.all([ jiraRequest, historyRequest, this.axios.post(`/SmsGateway/SendCustomMessage`, customMessage) ])
        .then(this.axios.spread((jiraResponse, historyResponse, messageResponse) => {
          if (jiraResponse == null || historyResponse == null || messageResponse == null) {
            this.sendingWasSuccessful = false;
            return;
          }
          if (jiraResponse.status == null || historyResponse.status == null || messageResponse.status == null) {
            this.sendingWasSuccessful = false;
            return;
          }
          if (jiraResponse.status != 200 || historyResponse.status != 200 || messageResponse.status != 200) {
            this.sendingWasSuccessful = false;
            return;
          }
          this.error_clear();
          this.sendingWasSuccessful = true;
        }))
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          this.sendingWasSuccessful = false;
        })
        .finally(() => {
          this.loading = false;
          if (this.sendingWasSuccessful == true) {
            window.setTimeout(() => {
              this.$router.push(`/installation/${ this.installationId }`)
            }, 2500);
          }
        });
    }
  }
}
</script>
